import React, { useState } from "react";
import { Formik, Form } from "formik";
import { VStack, Center, chakra } from "@chakra-ui/react";
import { NameInput, EmailInput, SubjectInput, MessageTextArea } from "./fields"
import { SubmitButton, ServerStateMessage } from './components'
import { validationSchema } from "./validationSchema"
import { handleSubmit } from "./utils"

const ChakraForm = chakra(Form)

export const ContactForm = props => {
  const initialValues = { name: "", email: "", subject: "", message: "" };
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => setServerState({ ok, msg });
  const onSubmit = (values, actions) =>
    handleSubmit({ values, actions }, handleServerResponse)

  const formikProps = { initialValues, validationSchema, onSubmit };
  return (
    <>
      <Formik {...formikProps}>
        {({ isSubmitting }) => (
          <ChakraForm w="full" maxW={300} {...props}>
            <VStack spacing={3} w="full" my="auto">
              <NameInput />
              <EmailInput />
              <SubjectInput />
              <MessageTextArea />
            </VStack>
            <Center mt={9} w="full">
              <SubmitButton isSubmitting={isSubmitting} />
            </Center>
            <ServerStateMessage state={serverState} />
          </ChakraForm>
        )}
      </Formik>

    </>
  );
};

// {/* {modal.open ? ( */ }
// {/*   modal.view === "Error" ? ( */ }
// {/*     <Modal close={close} content={<Error />} /> */ }
// {/*   ) : modal.view === "Success" ? ( */ }
// {/*     <Modal close={close} content={<Success />} /> */ }
// {/*   ) : null */ }
// {/* ) : null} */ }

// const Error = () => {
// return (
// {/* <div className="Contact--Error"> */}
// {/* <h2>Oops</h2> */}
// {/* <p> */}
// It appears that one of the <span>required*</span> fields is missing
// text. Please make sure to have all <span>required*</span> fields filled
// before submitting.
// </p>
// </div>
// );
// };

// const Success = () => {
// return (
// {/* <div className="Contact--Success"> */}
// {/* <h2>Success</h2> */}
// {/* <div className="Contact--Success-content"> */}
// {/* <h4>Thanks for Reaching Out!</h4> */}
// {/* <p>I will get back to you as soon as I can.</p> */}
// </div>
// </div>
// );
// };

export default ContactForm;
