import { mode } from "@chakra-ui/theme-tools";

export default {
  global: props => ({
    body: {
      bg: mode("white.200", "#0c111f")(props),
      color: mode("black.200", "#ddd")(props),
    },
  }),
};
