import axios from "axios";

export const submitForm = async ({ cancelToken, data, onSuccess, onError }) => {
  try {
    const resp = await axios({
      method: "POST",
      url: `https://formcarry.com/s/ls4AfO00EbN`,
      data: data,
      cancelToken: cancelToken,
      headers: {
        Accept: "application/json",
      },
    });
    if (resp.status === 200) onSuccess();
  } catch (e) {
    console.log(e);
    onError(e);
  }
};
