import ZenGardenPhoto from "../../assets/images/projects/zen-garden.png";
import HtmlCSSJS from "../../assets/images/technologies/html-css-js.png";

export const zenGarden = {
  title: "Zen Garden",
  image: {
    src: ZenGardenPhoto,
    alt: "CSS Zen Garden",
  },
  summary: "This is a clone of the csszengarden.com home page",
  link: "http://css-zen-garden.surge.sh",
  github:
    "https://github.com/steve-rodri/vs-assignments/tree/master/level-2/css-zen-garden",
  description:
    "This is a page I built to demonstrate my skills with HTML and CSS. The page is fully responsive",
  technologies: [{ src: HtmlCSSJS, alt: "HTML-CSS-JS" }],
};

export default zenGarden;
