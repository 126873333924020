import React from "react";
import { Center } from "@chakra-ui/react"
import { Section, Project } from "../../components"

export const ProjectPage = (props) => {
  return (
    <Section minH={0} p={[0, 0, 0, 0, 0]} mt={{ base: 4, md: 8 }} mb={4}>
      <Center h={{ md: "full" }} w={{ md: "full" }}>
        <Project {...props} showDetails />
      </Center>
    </Section>
  );
};

export default ProjectPage;
