import React from "react"
import { Grid } from "@chakra-ui/react"
import { ProjectImage, ProjectDetails } from "./components"

export const Project = ({ showDetails, image, ...rest }) => {
  return (
    <Grid gap={{ base: 8, xl: 12 }} autoFlow={{ md: "column" }}>
      <ProjectImage
        {...image}
        title={rest.title}
        linkToDetail={!showDetails}
        maxW={{ base: "full", md: 500, xl: 550 }}
        borderRadius={{ md: "sm" }}
      />
      <ProjectDetails {...rest} show={showDetails} />
    </Grid>
  )
}

export default Project;
