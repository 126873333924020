import React from "react";
import { Grid, Box } from "@chakra-ui/react"
import { ContactForm, PhotoAndSocial } from "./components";
import * as Chakra from "@chakra-ui/react"

export const Contact = (props) => {
  const borderColor = Chakra.useColorModeValue("gray.500", "gray.200");
  const borderWidth = Chakra.useBreakpointValue({ xs: null, md: 3 });
  const autoFlow = Chakra.useBreakpointValue({
    xs: "row",
    md: "column",
    "3xl": "column",
  });
  return (
    <Grid
      w="full"
      templateColumns={{ base: "1fr", md: "1fr auto 1fr" }}
      autoFlow={autoFlow}
      borderWidth={borderWidth}
      borderColor={borderColor}
      alignItems="center"
      justifyItems="center"
      borderStyle="solid"
      borderRadius="md"
      gap={{ md: 8 }}
      p={{ md: 8 }}
      {...props}
    >
      <PhotoAndSocial />
      <Divider />
      <ContactForm />
    </Grid>
  );
};

const Divider = () => {
  const bgColor = Chakra.useColorModeValue("gray.500", "gray.200");
  const width = Chakra.useBreakpointValue({ xs: null, md: "3px" });
  return (
    <Box
      height={{ base: 0, md: "full" }}
      bgColor={bgColor}
      width={width}
    />
  )
}
