import React from "react";
import { Center, Text } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Center h={65}>
      <Text>© 2022 Steve Rodriguez</Text>
    </Center>
  );
};

export default Footer;
