import CryptoRankingsPhoto from "../../assets/images/projects/crypto-rankings/rankings-light-icons-2.png";
import ReactLogo from "../../assets/images/technologies/react.png";

export const cryptoRankings = {
  id: 1,
  title: "Crypto Rankings",
  image: {
    src: CryptoRankingsPhoto,
    alt: "Crypto Rankings",
  },
  summary: "A site that ranks crypto currencies by market capitalization",
  link: "http://crypto-rankings.surge.sh/",
  github:
    "https://github.com/steve-rodri/vs-assignments/tree/master/level-4/crypto-rankings",
  description:
    "This site utilizes the nomics API to display information about cryptocurrencies. You can view a list of crypto currencies on the rankings page, keep a list of favorites by tapping on the star icon next to the coin, as well as get some detailed information about a coin by clicking on one of them.",
  technologies: [{ src: ReactLogo, alt: "React" }],
};

export default cryptoRankings;
