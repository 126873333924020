import React from "react";
import { Button, Box, Heading, useBreakpointValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const Header = () => {
  const broken = useBreakpointValue({ xs: false, md: true });
  if (!broken) return null;
  return (
    <Box
      h={100}
      mr={8}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <NavBar />
    </Box>
  );
};

const NavBar = () => (
  <Box display="grid" gridAutoFlow="column" gridGap={25}>
    <Link to="/">
      <Button variant="link" _focus={{ boxShadow: "none " }}>
        <Heading as="h3" size="md">
          Portfolio
        </Heading>
      </Button>
    </Link>
    <Link to="/contact">
      <Button variant="link" _focus={{ boxShadow: "none " }}>
        <Heading as="h3" size="md">
          Contact
        </Heading>
      </Button>
    </Link>
  </Box>
);

export default Header;
