import React from 'react'
import { Heading } from "@chakra-ui/react"

export const DetailHeader = ({ breakpointBroken, ...rest }) => {
  return <Title {...rest} />;
}

const Title = ({ title }) => {
  return (
    <Heading as="h1" fontSize={["xl", "2xl", "3xl"]} >{title}</Heading>
  )
}

export default DetailHeader
