import NJForwardPhoto from "../../assets/images/projects/nj-forward.png";
import ReactLogo from "../../assets/images/technologies/react.png";

export const njForward = {
  title: "NJ Forward",
  image: {
    src: NJForwardPhoto,
    alt: "NJ Forward",
  },
  summary: "The Governor of New Jersey's Inaugural Ball Registration",
  link: "https://njforward.org",
  github: "https://github.com/steve-rodri/inaugural-ball",
  description:
    "A website built for the Governor of NJ to take registration information and payment to attending the ball. I utilized the Jotform API to take registration information and Authorize.net for the Payment gateway.",
  technologies: [
    { src: ReactLogo, alt: "React" },
    { src: null, alt: "Chakra-UI" },
    { src: null, alt: "JotForm" },
    { src: null, alt: "Authorize.net" },
    { src: null, alt: "Formik" },
    { src: null, alt: "CloudFlare" },
    { src: null, alt: "Vercel" },
  ],
};

export default njForward;
