import React from "react";
import { Header, Pages, Footer } from "./components"
import { Grid, useBreakpointValue } from "@chakra-ui/react";

export const App = () => {
  const broken = useBreakpointValue({ base: false, md: true });
  return (
    <Grid minH="100vh" maxW={1200} mx="auto" px={4}>
      <Grid templateRows={broken ? "auto 1fr auto" : "1fr auto"}>
        <Header />
        <Pages />
        <Footer />
      </Grid>
    </Grid>
  );
};

export default App;
