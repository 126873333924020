import React from 'react'
import { Link, Button } from "@chakra-ui/react"
import { GitHubIcon, LaptopIcon } from "../../../../icons";

export const GitHubButton = ({ github, size }) => {
  if (!github) return null;
  return (
    <Link href={github} isExternal>
      <Button
        size={size}
        leftIcon={<GitHubIcon />}
        aria-label="Github"
        w={["full", "full", "auto"]}
      >
        View GitHub
      </Button>
    </Link>
  )
};

export const SiteButton = ({ link, size }) => {
  if (!link) return null
  return (
    <Link href={link} isExternal>
      <Button size={size} leftIcon={<LaptopIcon />} w={["full", "full", "auto"]}>
        View Site
      </Button>
    </Link>
  )
};
