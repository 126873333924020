import React from "react";
import { VStack, Heading } from "@chakra-ui/react";

export const Section = (props) => {
  const { headingText, titleProps = {}, children, ...rest } = props
  return (
    <VStack
      minH={{ base: "100vh", md: "90vh" }}
      spacing={[8, 12, 16]}
      px={[4, 8, 12, 16]}
      mb={[20, 28, 36]}
      {...rest}
    >
      <SectionTitle text={headingText} {...titleProps} />
      {children}
    </VStack>
  );
};

const SectionTitle = React.forwardRef(({ text, ...rest }, ref) => {
  if (!text) return null;
  return (
    <Heading
      ref={ref}
      alignSelf="start"
      fontSize={["xl", "2xl", "3xl", "4xl"]}
      {...rest}
    >
      {text}
    </Heading>
  )
})

export default Section;
