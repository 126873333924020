import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string().required("Name is Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is Required"),
  subject: Yup.string().max(100, "Must be 100 characters or less"),
  message: Yup.string().required("Message is Required"),
});
