import BurnAppPhoto from "../../assets/images/projects/burn-app.png"
import ReactLogo from "../../assets/images/technologies/react.png";

export const burnApp = {
  title: "The Burn App by Cara Loren",
  image: {
    alt: "The Burn App",
    src: BurnAppPhoto
  },
  summary: "A Social Media Influencer Fitness App",
  link: "https://getburnapp.com",
  description:
    "A mobile app built for Fitness influencer Cara Loren. This project took around 1.5 years to launch. It uses Firebase Authentication together with a custom built Strapi Backend to handle content. At the time there were no tools like NativeBase where you could write styles using utility props like popular Design frameworks such as Chakra UI or Tailwind CSS, so I baked in my own custom design framework.",
  technologies: [
    { src: ReactLogo, alt: "React Native" },
    { src: null, alt: "Formik" },
    { src: null, alt: "Strapi" },
    { src: null, alt: "Firebase" },
    { src: null, alt: "Revenue Cat" },
    { src: null, alt: "Notifee" },
    { src: null, alt: "Sentry" },
    { src: null, alt: "Intercom" },
    { src: null, alt: "Styled Components" },
    { src: null, alt: "React-Spring" },
  ],
};
export default burnApp;

