import React from "react";
import { Center } from "@chakra-ui/react";
import { Section, Contact as ContactForm } from "../../components"

export const ContactPage = () => {
  return (
    <Section minH={0} p={0} mt={{ base: 8 }} mb={4}>
      <Center h="full" w="full" maxW={800}>
        <ContactForm />
      </Center>
    </Section>
  );
};

export default ContactPage;
