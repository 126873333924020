import React from "react"
import { Center, Text } from "@chakra-ui/react"

export const ServerStateMessage = ({ state }) => {
  return (
    state && (
      <Center pos="absolute" mt="9px">
        <Text fontSize="sm" color={!state.ok ? "red" : ""}>
          {state.msg}
        </Text>
      </Center>
    )
  )
}
