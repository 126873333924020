import React from 'react'
import { VStack, useBreakpointValue } from "@chakra-ui/react";
import { DetailHeader, DetailBody, DetailFooter } from "./components"

export const ProjectDetails = ({ show, ...rest }) => {
  const broken = useBreakpointValue({ base: false, md: true });
  if (!show) return null;
  return (
    <>
      <VStack
        align="start"
        spacing={{ base: 2 }}
        w="full"
      >
        <DetailHeader breakpointBroken={broken} {...rest} />
        <DetailBody {...rest} />
        <DetailFooter breakpointBroken={broken} {...rest} />
      </VStack>
    </>
  );
};

export default ProjectDetails;
