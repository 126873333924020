import React from "react";
import { VStack, Image, Center } from "@chakra-ui/react";
import ProfilePhoto from "../../../assets/images/profile_photo.jpg";
import SocialIcons from "../../SocialIcons";

export const PhotoAndSocial = () => {
  return (
    <Center w="full" >
      <VStack spacing={6}>
        <Image boxSize={{ base: "12rem", lg: "14rem" }} src={ProfilePhoto} alt="Me" borderRadius="md" />
        <SocialIcons />
      </VStack>
    </Center>
  );
};

