import React from "react";
import { IconButton, Grid, Link } from "@chakra-ui/react";
import { GitHubIcon, LinkedInIcon } from "./icons";

export const SocialIcons = () => {
  return (
    <Grid autoFlow="column" gap={25} justifyItems="space-between">
      <LinkedInButton />
      <GitHubButton />
    </Grid>
  );
};

const GitHubButton = () => {
  return (
    <Link isExternal href="https://www.github.com/steve-rodri">
      <IconButton variant="ghost" icon={<GitHubIcon />} fontSize={30} />
    </Link>
  );
};

const LinkedInButton = () => {
  return (
    <Link isExternal href="https://www.linkedin.com/in/steve-rodri">
      <IconButton variant="ghost" icon={<LinkedInIcon />} fontSize={30} />
    </Link>
  );
};

export default SocialIcons;
