import React from "react";
import { Button } from "@chakra-ui/react";

export const SubmitButton = ({ isSubmitting }) => {
  return (
    <Button
      type="submit"
      isLoading={isSubmitting}
      loadingText="Submitting"
      size="lg"
      w="full"
      maxW={415}
    >
      Submit
    </Button>
  )
}
