import ReversiPhoto from "../../assets/images/projects/reversi/desktop/mains.png"
import HtmlCSSJS from "../../assets/images/technologies/html-css-js.png";
import ReactLogo from "../../assets/images/technologies/react.png";

export const reversi = {
  title: "Reversi",
  image: {
    src: ReversiPhoto,
    alt: "Reversi",
  },
  summary: "A strategy board game, played on an 8×8 uncheckered board.",
  link: "http://othello-game.surge.sh/",
  github: "http://github.com/steve-rodri/othello",
  description:
    "This is a 1-2 player board game. There are sixty-four identical game pieces called discs, which are light on one side and dark on the other. Players take turns placing discs on the board with their assigned color facing up.",
  technologies: [
    { src: HtmlCSSJS, alt: "HTML-CSS-JS", key: 1 },
    { src: ReactLogo, alt: "React", key: 2 },
  ],
};

export default reversi;
