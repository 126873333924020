import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import styles from "./styles";

const config = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

const breakpoints = createBreakpoints({
  xs: "24em",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
  "3xl": "135em",
});

const colors = {
  dark: "#0c111f",
  "sky-blue": "#3051b0",
};

const overrides = {
  styles,
  breakpoints,
  colors,
};

export const theme = extendTheme({ config, ...overrides });

export default theme;
