import { submitForm } from "../../../../../services/formcarry";

export const handleSubmit = async (...params) => {
  const [{ values, actions }, handleServerResponse] = params
  const { name, email, message } = values;
  if (!name || !email || !message) return;
  actions.setSubmitting(true);
  await submitForm({
    data: values,
    onSuccess: () => {
      actions.setSubmitting(false);
      actions.resetForm(false);
      handleServerResponse(
        true,
        "Thanks for your message! I'll be in touch soon."
      );
    },
    onError: err => {
      actions.setSubmitting(false);
      handleServerResponse(false, err.message || "Error");
    },
  });
};
