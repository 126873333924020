import React from "react";
import { Link } from "react-router-dom";
import { VStack, Heading, Stack, Button } from "@chakra-ui/react";

export const Introduction = ({ portfolioRef }) => {
  return (
    <VStack align="start" spacing={38}>
      <Headline />
      <Stack direction={{ base: "column", sm: "row" }} spacing={4}>
        <GetInTouchButton />
        <ViewMyWorkButton onClick={() => portfolioRef.current.scrollIntoView({ behavior: "smooth" })} />
      </Stack>
    </VStack>
  );
};

const ResponsiveHeading = ({ children, ...rest }) => (
  <Heading as="h1" fontSize={["2xl", "3xl", "4xl"]} fontWeight="bold" {...rest}>
    {children}
  </Heading>
)

const Headline = () => (
  <VStack align="start" spacing={5}>
    <ResponsiveHeading>Hello, I'm Steve.</ResponsiveHeading>
    <ResponsiveHeading>A Software Engineer based in NY,</ResponsiveHeading>
    <ResponsiveHeading>Focused on building awesome and useful apps and websites.</ResponsiveHeading>
  </VStack>
);

const GetInTouchButton = () => (
  <Link to="/contact">
    <Button size="lg">Get in Touch</Button>
  </Link>
);

const ViewMyWorkButton = (props) => (
  <Button size="lg" {...props}>View My Work</Button>
)

export default Introduction;
