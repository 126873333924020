import burnApp from "./burnApp";
import rockTheVote from "./rockTheVote";
import njForward from "./njForward";
import reversi from "./reversi";
import cryptoRankings from "./cryptoRankings";
import zenGarden from "./zenGarden";

export const projects = [
  burnApp,
  njForward,
  rockTheVote,
  cryptoRankings,
  reversi,
  zenGarden,
].map((p, i) => {
  p.key = i;
  return p;
});

export default projects;
