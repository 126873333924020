import React from "react";
import { Center } from "@chakra-ui/react"
import { Introduction, Projects } from "./components"
import { Section, Contact as ContactForm } from "../../components"

export const HomePage = props => {
  const portfolioRef = React.useRef()
  return (
    <>
      <HeroSection{...props} portfolioRef={portfolioRef} />
      <Portfolio {...props} portfolioRef={portfolioRef} />
      <ContactSection {...props} />
    </>
  );
};

const HeroSection = (props) => {
  return (
    <Section>
      <Center h="full">
        <Introduction {...props} />
      </Center>
    </Section>
  )
}

const Portfolio = ({ portfolioRef }) => {
  return (
    <Section
      headingText="Here is some of my work..."
      titleProps={{ alignSelf: { base: "start", lg: "center" }, ref: portfolioRef }}
    >
      <Projects />
    </Section>
  );
};

const ContactSection = (props) => {
  return (
    <Section
      {...props}
      minH={0}
      mt={{ lg: 40 }}
      mb={10}
      headingText="Here's how we can get in touch..."
      titleProps={{ alignSelf: { base: "start", sm: "center" } }}
    >
      <Center h="full" w="full" maxW={800}>
        <ContactForm />
      </Center>
    </Section>
  );
};

export default HomePage;
