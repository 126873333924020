import React from 'react'
import { Stack } from "@chakra-ui/react"
import { GitHubButton, SiteButton } from "./buttons";

export const DetailFooter = ({ breakpointBroken, ...rest }) => {
  // if (breakpointBroken) return null;
  return <ButtonStack {...rest} size={{ base: "lg", md: "md" }} />;
}

const ButtonStack = props => (
  <Stack
    w="full"
    direction={["column", "column", "row", "column", "row"]}
    spacing={4}
  >
    <SiteButton {...props} />
    <GitHubButton {...props} />
  </Stack>
);

export default DetailFooter;
