import { TextInput, TextArea } from "./components"

export const NameInput = (props) => (
  <TextInput
    label="Name"
    name="name"
    type="text"
    placeholder="Name"
    isRequired
    {...props}
  />
)

export const EmailInput = props => (
  <TextInput
    label="Email"
    name="email"
    type="email"
    placeholder="Email"
    isRequired
    {...props}
  />
)

export const SubjectInput = props => (
  <TextInput
    label="Subject"
    name="subject"
    type="text"
    placeholder="Subject"
    {...props}
  />
)

export const MessageTextArea = props => (
  <TextArea label="Message" name="message" isRequired {...props} />
)
