import React from 'react'
import { Image as ChakraImage, useColorModeValue } from "@chakra-ui/react"
import { Link } from "react-router-dom";
import { projectPathname } from "../../../utils"

const Image = props => {
  const borderColor = useColorModeValue("gray.500", "gray.200");
  const borderRadius = useColorModeValue("md", "sm")
  return (
    <ChakraImage
      {...props}
      borderWidth={3}
      borderStyle="solid"
      borderColor={borderColor}
      borderRadius={borderRadius}
    />
  )
}

export const ProjectImage = ({ linkToDetail, title, ...rest }) => {
  if (linkToDetail) return (
    <Link to={`/projects/${projectPathname(title)}`} h="full" w="full" {...rest}>
      <Image{...rest} />
    </Link>

  )
  return <Image {...rest} />
}

export default ProjectImage;
