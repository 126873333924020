import React from 'react'
import { VStack, HStack, Heading, Text } from "@chakra-ui/react"

export const DetailBody = props => {
  return (
    <>
      <Description {...props} />
      <Technologies {...props} />
    </>
  );
};

const Description = ({ summary, description }) => (
  <VStack align="start">
    <Heading as="h4" fontSize={["sm", "md", "lg"]}>
      {summary}
    </Heading>
    <Text fontSize={["xs", "sm", "md"]}>{description}</Text>
    <Technologies />
  </VStack>
);

const Technologies = () => (
  <HStack></HStack>
);
