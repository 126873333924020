import React from "react";
import { chakra } from "@chakra-ui/react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMobile, faLaptop, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedinIn,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
  faMobile,
  faLaptop,
  faTimes,
  faGithub,
  faLinkedinIn,
  faInstagram,
  faTwitter
);

const ChakraIcon = chakra(FontAwesomeIcon);

export function MobileIcon(props) {
  return <ChakraIcon {...props} icon="mobile" />;
}

export function LaptopIcon(props) {
  return <ChakraIcon {...props} icon="laptop" />;
}

export function GitHubIcon(props) {
  return <ChakraIcon {...props} icon={["fab", "github"]} />;
}

export function LinkedInIcon(props) {
  return <ChakraIcon {...props} icon={["fab", "linkedin-in"]} />;
}

export function InstagramIcon(props) {
  return <ChakraIcon {...props} icon={["fab", "instagram"]} />;
}

export function TwitterIcon(props) {
  return <ChakraIcon {...props} icon={["fab", "twitter"]} />;
}

export function TimesIcon(props) {
  return <ChakraIcon {...props} icon="times" />;
}
