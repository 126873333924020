import RockTheVotePhoto from "../../assets/images/projects/rock-the-vote/desktop-dark.png";
import ReactLogo from "../../assets/images/technologies/react.png";

export const rockTheVote = {
  title: "Rock The Vote",
  image: {
    src: RockTheVotePhoto,
    alt: "Rock The Vote",
  },
  summary: "This is a Reddit clone",
  link: "https://rock-the-vote.vercel.app",
  github:
    "https://github.com/steve-rodri/vs-assignments/tree/master/level-6/rock-the-vote",
  description:
    "If you're familiar with how reddit works you'll know that you can upvote or downvote posts. It's the same idea with this app. You'll be able to login and get a list of \"political issues\" to upvote or downvote. You can also create your own issues, and add comments to your own issues as well as any others.",
  technologies: [
    { src: ReactLogo, alt: "React" },
    { src: null, alt: "Chakra-UI" },
    { src: null, alt: "Formik" },
    { src: null, alt: "Express" },
    { src: null, alt: "Mongoose" },
    { src: null, alt: "MongoDB" },
    { src: null, alt: "Lerna" },
    { src: null, alt: "Faker" },
    { src: null, alt: "Heroku" },
    { src: null, alt: "Surge" },
    { src: null, alt: "CloudFlare" },
  ]
};

export default rockTheVote;
