import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HomePage, ContactPage, ProjectPage } from "../pages";
import { projectPathname } from "../utils";
import projects from "./projects";

export const Pages = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/projects/*" element={<ProjectRoutes />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

const ProjectRoutes = () => {
  return (
    <Routes>
      {projects.map(project => (
        <Route
          key={project.key}
          path={`:${projectPathname(project.title)}`}
          element={<ProjectPage {...project} />}
        />
      ))}
    </Routes>
  )
}

export default Pages;
