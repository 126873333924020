import React from "react";
import { Grid, useBreakpointValue } from "@chakra-ui/react";
import { Project } from "../../../components"
import projects from "../../../components/projects";

export const Projects = () => {
  const showDetails = useBreakpointValue({ base: false, lg: true })
  return (
    <Grid gap={[4, 8, 12, 16, 36]}>
      {
        projects.map(({ key, ...rest }) => (
          <Project key={key} showDetails={showDetails} {...rest} />
        ))
      }
    </Grid >
  );
};
