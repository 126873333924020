import React from "react";
import { useField } from "formik";
import { FormControl, FormLabel, FormErrorMessage, Textarea } from "@chakra-ui/react";

export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FormControl
        id={props.id || props.name}
        isRequired={props.isRequired}
      >
        <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>
        <Textarea {...field} {...props} />
        {meta.touched && meta.error ? (
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        ) : null}
      </FormControl>
    </>
  );
};
